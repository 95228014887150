:root{
	--asa-radius:30px;
	--portfolio-radius:30px;
}
.Portfolio{
	background-image: url('./assets/leather.png');
	background-color: rgb(98, 74, 46);
}
.Portfolio-inner{
	overflow:auto;
}
@media only screen and (min-width:769px){
	.Portfolio{
		position:fixed;
		height:100%;
		width:100%;
		top:-100%;
		left:0;
		transition:top .3s;
		border-radius:0 0 var(--portfolio-radius) var(--portfolio-radius);
	}
	.Portfolio:before{
		content:' ';
		width:300px;
		height:50px;
		position:absolute;
		top:100%;
		left:CALC(50% - 150px);
		background:#624a2e;
		border-radius:0 0 var(--asa-radius) var(--asa-radius);
		background-image: url('./assets/leather.png');
		background-color: rgb(98, 74, 46);
	}
	.Portfolio:after{
		content:' ';
		width:250px;
		height:30px;
		position:absolute;
		top:100%;
		left:CALC(50% - 125px);
		background:#eee;
		border-radius:0 0 var(--asa-radius) var(--asa-radius);
	}
	.Portfolio:not(.active):hover{
		top:-95%;
	}
	.Portfolio.active{
		top:0;
	}
	.Portfolio-inner{
		width:CALC(100% - 30px);
		margin-left:15px;
		height:CALC(100% - 20px);
		border-radius:0 0 var(--portfolio-radius) var(--portfolio-radius);
	}
}
@media only screen and (max-width:768px){
	.Portfolio{
		position:fixed;
		width:100%;
		height:100%;
		left:-100%;
		top:0;
		transition:left .3s;
		border-radius:0 var(--portfolio-radius) var(--portfolio-radius) 0;
	}
	.Portfolio:before{
		content:' ';
		height:300px;
		width:25px;
		position:absolute;
		left:100%;
		top:CALC(50% - 150px);
		background:#624a2e;
		border-radius:0 var(--asa-radius) var(--asa-radius) 0;
		background-image: url('./assets/leather.png');
		background-color: rgb(98, 74, 46);
	}
	.Portfolio:after{
		content:' ';
		height:250px;
		width:10px;
		position:absolute;
		left:100%;
		top:CALC(50% - 125px);
		background:#eee;
		border-radius:0 var(--asa-radius) var(--asa-radius) 0;
	}
	.Portfolio.active{
		left:0;
	}
	.Portfolio-inner{
		height:CALC(100% - 30px);
		margin-top:15px;
		width:CALC(100% - 20px);
		border-radius:0 var(--portfolio-radius) var(--portfolio-radius) 0;
	}
}

.Portfolio-inner{
	background:url('./assets/background.png');
}

.Portfolio-title{
	margin:15px 0;
	font-family:'GTW';
	color:rgb(98, 74, 46);
}
.Portfolio-title > span{
	cursor:pointer;
	user-select:none;
}


.Portfolio-wrapper{
	width:100%;
	display:flex;
	flex-wrap:wrap;
	justify-content:space-around;
}
.Portfolio-card{
	position:relative;
	box-sizing:border-box;
	width:40%;
	background:white;
	margin:30px 0;
	min-height:200px;
	box-shadow: 0px 1px 7px rgba(0,0,0,.2);
}
@media only screen and (max-width:768px){
	.Portfolio-wrapper{
		flex-direction:column;
		align-items:center;
	}
	.Portfolio-card{
		width:80%;
	}
}


.Portfolio-card:after,
.Portfolio-card:before {
	background: url(http:/api.thumbr.it/whitenoise-361x370.png?background=e6e6e6ff&noise=626262&density=15&opacity=15);
	content: '';
	height: 50px;
	position: absolute;
	top: -25px;
	width: 100px;
	transition:.3s opacity;
}
.Portfolio-card:after {
	left: -50px;
	transform: rotate(-45deg);
	box-shadow: 0 7px 7px -7px hsla(0, 0% ,0%, 0.5);
}
.Portfolio-card:before {
	right: -50px;
	top: CALC(100% - 25px	);
	transform: rotate(-45deg);
	box-shadow: 0 -7px 7px -7px hsla(0, 0% ,0%, 0.5);
}

.Portfolio-card:hover:before{
	opacity:0;
}
.Portfolio-card:hover:after{
	opacity:0;
}


.Portfolio-inner::-webkit-scrollbar {
	width: 3px;
	height:3px;
}
.Portfolio-inner::-webkit-scrollbar-track {
	background: transparent;
}
.Portfolio-inner::-webkit-scrollbar-thumb {
	background: black;
}
.Portfolio-inner::-webkit-scrollbar-thumb:hover {
	background: black;
}


.Portfolio-card{
	display:flex;
	cursor:pointer;
	user-select:none;
}
.Portfolio-card img{
	height:200px;
	width:auto;
}
.Portfolio-card .card-meta{
	flex-grow:1;
}
.Portfolio-card .card-meta h3{
	font-family:'GTW';
	margin:0;
	margin-top:15px;
	padding:0;
	width:100%;
}
.Portfolio-card .card-meta p{
	text-align:left;
	padding:0 8px;
	font-family: 'GTWL';
}
.Portfolio-card .card-meta p span{
	font-weight:bold;
}
@media only screen and (max-width:768px){
	.Portfolio-card{
		flex-direction:column;
	}
	.Portfolio-card > *{
		width:100%;
	}
}