@font-face {
	font-family: 'GTW';
		src: url('./assets/Fonts/GTWalsheimProBold.ttf')  format('truetype');
}

@font-face {
	font-family: 'GTWL';
		src: url('./assets/Fonts/GTWalsheimProRegular.ttf')  format('truetype');
}

html,body,#root{ 
	margin:0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
				"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
				sans-serif;
	height:100%;
	background:#eee;
}
.Initial {
	text-align: center;
	flex-wrap:wrap;
	padding:0 90px;
	height:100%;
}


.Initial-header {
	background-color: #eee;
	min-height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #666;
}

.Initial-logo {
	width: 25%;
}
.Initial-info{
	max-width:75%;
	min-width:75%;
	box-sizing:border-box;
	text-align:left;
	padding-left:90px;
	font-family:'GTWL';
}
.Initial-title{
	font-family: 'GTW';
}
.Initial-title span:not(:last-child):after{
	content:' ';
	display:inline-block;
	height:20px;
	width:1px;
	background:#666;
	margin:0 15px;
}
.Initial-text{
	text-align:justify;
}
@media only screen and (min-width:921px){
	html,body,#root{
		overflow:hidden;
	}
}
@media only screen and (max-width:920px){
	.Initial-logo{
		width:50%;
	}
	.Initial-header{
		flex-direction:column;
	}
	.Initial-info{
		padding:0;
	}
}
@media only screen and (max-width:575px){
	.Initial{
		padding:0;
	}
	.Initial-title span{
		font-size:.7em;
	}
}

.languages{
	font-family:'GTW';
	position:fixed;
}
.languages span:not(:first-child){
	margin-left:15px;
}

.link{
	color:#666;
	cursor:pointer;
	user-select:none;
	transition:.1s color;
}
.link.active,.link:hover{
	color:#da3e00;
}
